<template>
  <v-row>
    <v-col cols="12" class="d-flex justify-space-between align-stretch">
      <sheet
        v-for="sReportKey in arReportList"
        :key="`report.${sReportKey}`"
        @click="toReportRoute(sReportKey)"
        class="mt-0 mb-4 mx-4"
      >
        <template #head>
          <v-toolbar flat>
            <v-toolbar-title v-text="$t(`report.${sReportKey}`)" />
          </v-toolbar>
        </template>

        <p v-text="$t(`report.${sReportKey}.description`)" />
      </sheet>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class DashReports extends Vue {
  get arReportList() {
    return ["subdaily", "unpaid", "receipt", "sales"];
  }

  async toReportRoute(sReport: string) {
    await this.$router.push({ name: `reports.${sReport}` });
  }
}
</script>
